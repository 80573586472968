import React, {useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';
import CartList from 'rev.sdk.js/Components/CartList';
import moment from 'moment';
import numeral from 'numeral';
import {Button, Collapse, Form, Input, PageHeader, Table} from 'antd';
import * as AppActions from '../../AppActions';

const {Panel} = Collapse;

const PAYMENT_STATUS = {
  pending: 'pending',
  waiting: 'waiting',
  success: 'success',
  failure: 'failure',
};

const PAYMENT_STATUS_DISPLAY = {
  pending: {
    key: 'pending',
    label: '尚未付款',
  },
  waiting: {
    key: 'waiting',
    label: '款項確認中',
  },
  success: {
    key: 'success',
    label: '付款成功',
  },
  failure: {
    key: 'failure',
    label: '付款失敗',
  },
};

function Filed(props) {
  const {name, value, addonBefore = null, addonAfter = null} = props;
  return (
    <Form.Item
      colon={false}
      label={name}
      labelAlign="left"
      style={{display: 'flex', flexDirection: 'column'}}>
      <Input
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        value={value}
        disabled
      />
    </Form.Item>
  );
}

function OrderDetail(props) {
  const {order} = props;

  if (!order) {
    return null;
  }

  return (
    <div>
      <PageHeader
        title="返回"
        onBack={() => AppActions.navigate('/profile/orders')}
        style={{padding: 0}}
      />
      <Collapse defaultActiveKey={[1]}>
        <Panel header="訂單內容" key={1}>
          <CartList cartItems={order.items} disabled={true} />
        </Panel>
        <Panel header="訂單資訊" key={5}>
          <Filed name="訂單編號" value={order.order_number} />
          <Filed name="訂單備註" value={order.order_note} />
          <Filed name="訂單總金額" value={order.total} />
          <Filed
            name="訂單日期"
            value={moment(order.created).format('YYYY-MM-DD hh:mm')}
          />
          <Filed
            name="金流狀態"
            value={
              PAYMENT_STATUS_DISPLAY[order.payment_status]?.label || '尚未定義'
            }
          />
        </Panel>
        <Panel header="購買人資訊" key={2}>
          <Filed name="購買人姓名" value={order.buyer_name} />
          <Filed name="購買人電話" value={order.buyer_phone} />
          <Filed name="購買人信箱" value={order.buyer_email} />
          {!!order.buyer_address && (
            <Filed
              name="購賣人地址"
              value={order.buyer_address}
              addonBefore={
                <span>
                  {`${order.buyer_zip} ${order.buyer_city} ${order.buyer_district}`}
                </span>
              }
            />
          )}
          {!!order.buyer_tel && (
            <Filed
              name="市話"
              value={order.buyer_tel}
              addonAfter={<span>分機號碼 {order.buyer_tel_ext}</span>}
            />
          )}
        </Panel>
        <Panel header="收件人資訊" key={3}>
          <Filed name="收件人姓名" value={order.receiver_name} />
          <Filed name="收件人電話" value={order.receiver_phone} />
          <Filed name="收件人信箱" value={order.receiver_email} />
          {!!order.receiver_address && (
            <Filed
              name="收件人地址"
              value={order.receiver_address}
              addonBefore={
                <span>
                  {`${order.receiver_zip} ${order.receiver_city} ${order.receiver_district}`}
                </span>
              }
            />
          )}
          {!!order.receiver_tel && (
            <Filed
              name="市話"
              value={order.receiver_tel}
              addonAfter={<span>分機號碼 {order.receiver_tel_ext}</span>}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
}

function OrderPage(props) {
  const [user] = useOutlet('user');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const _fetchOrderList = async () => {
      try {
        AppActions.setLoading(true);
        const resp = await JStorageActions.fetchDocuments('order', {
          owner: user.sub,
        });
        setOrders(resp.results);
      } finally {
        AppActions.setLoading(false);
      }
    };
    _fetchOrderList();
  }, [user.sub]);

  const columns = [
    {
      title: '訂單編號',
      dataIndex: 'order_number',
      key: 'order_number',
      render: (data) => {
        return <div>{data}</div>;
      },
      width: 250,
    },
    {
      title: '總額',
      dataIndex: 'total',
      key: 'total',
      render: (data) => {
        return <div>$ {numeral(data).format('0,0')}</div>;
      },
      width: 250,
    },
    {
      title: '訂購日期',
      dataIndex: 'created',
      key: 'created',
      render: (data) => {
        return <div>{moment(data).format('YYYY-MM-DD')}</div>;
      },
      width: 150,
    },
    {
      title: '動作',
      dataIndex: 'order_number',
      key: 'action',
      render: (index) => {
        return (
          <div>
            <Button
              onClick={() => {
                AppActions.navigate(`/profile/order/?id=${index}`);
              }}>
              訂單詳細
            </Button>
          </div>
        );
      },
      width: 150,
    },
  ];

  return (
    <Table
      scroll={{x: 500}}
      pagination={false}
      dataSource={orders}
      columns={columns}
    />
  );
}

export default OrderPage;
